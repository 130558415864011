<template>
    <b-card title="Plusvàlua Municipal" border-variant="primary" class="text-left mt-2">
        <b-col lg="12" md="12" sm="12">
            <b-row v-for="(capital_gain, index) in client.capital_gains"
                   :key="capital_gain.id">
                <b-col sm="3">
                    <b-form-group label="Document" label-for="document">
                        <b-form-file
                            v-model="capital_gain.document_file"
                            placeholder="Tria un document"
                            drop-placeholder="Tria un document"
                            browse-text="Seleccionar"
                        />
                    </b-form-group>
                </b-col>
                <div v-if="capital_gain.document">
                    <a :href="baseUrl+capital_gain.document" target="_blank">
                        <feather-icon
                            icon="EyeIcon"
                            class="cursor-pointer mt-0 mt-md-2"
                            size="25"
                        />
                    </a>
                </div>
                <b-col sm="3">
                    <b-form-group label="Descripció" label-for="document">
                        <b-form-input v-model="capital_gain.description" autocomplete="false"
                                      placeholder="Descripció"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2" md="12" sm="12">
                    <b-form-group label="Data" label-for="date">
                        <flat-pickr
                            v-model="capital_gain.date_updated"
                            class="form-control"
                            placeholder="Data"
                            :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                        />
                    </b-form-group>
                </b-col>
                <b-col >
                    <b-form-group label="Sol·licitada" label-for="prorroga">
                        <div class="demo-left-spacing">
                            <b-form-radio-group
                                v-model="capital_gain.requested"
                                class="mt-1"
                            >
                                <b-form-radio
                                    v-for="bool in boolean"
                                    :key="bool.name"
                                    name="requested"
                                    :value="bool.value"
                                >
                                    {{ bool.name }}
                                </b-form-radio>
                            </b-form-radio-group>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Rebuda" label-for="rebuda">
                        <div class="demo-left-spacing">
                            <b-form-radio-group
                                v-model="capital_gain.received"
                                class="mt-1"
                            >
                                <b-form-radio
                                    v-for="bool in boolean"
                                    :key="bool.name"
                                    name="received"
                                    :value="bool.value"
                                >
                                    {{ bool.name }}
                                </b-form-radio>
                            </b-form-radio-group>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Pagada" label-for="paid">
                        <div class="demo-left-spacing">
                            <b-form-radio-group
                                v-model="capital_gain.paid"
                                class="mt-1"
                            >
                                <b-form-radio
                                    v-for="bool in boolean"
                                    :key="bool.name"
                                    name="paid"
                                    :value="bool.value"
                                >
                                    {{ bool.name }}
                                </b-form-radio>
                            </b-form-radio-group>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col>
                    <feather-icon
                        icon="TrashIcon"
                        class="cursor-pointer mt-md-2"
                        size="25"
                        @click="removeItemModel(index, capital_gain.id)"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="d-flex align-items-center justify-content-start" cols="12" md="12">
                    <b-button @click="addNewRowModel('capital_gain')">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                        />
                        <span>Afegir Plusvàlua</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-card>
</template>

<script>
import {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BFormRadio,
    BFormFile,
    BFormTextarea,
    BButton,
    BCard,
    BFormCheckbox,
    BFormRadioGroup,
} from 'bootstrap-vue'
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {baseUrl} from "@/constants/app"
import helpers from "@/resources/helpers/helpers"
import Swal from "sweetalert2"
import {boolean} from "@/constants/constants"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "CapitalGainsForm",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BButton,
        BCard,
        BFormCheckbox,
        BFormRadioGroup,

        vSelect,
        flatPickr,
    },
    data() {
        return {
            CatalanLocale,
            clients: [],
            baseUrl,
            helpers,
            boolean
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client: 'client',
        }),
    },
    methods: {
        addNewRowModel(flag) {
            this.client.capital_gains.push({
                description: null,
                requested: 0,
                paid: 0,
                date: null,
                received: 0,
                document: null,
                document_file: null,
                flag: flag,
            })
        },
        removeItemModel(index, modelDocumentId) {
            if (modelDocumentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('client/deleteTradingModelRegister', modelDocumentId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Model eliminat correctament', 'CheckIcon', 'success')
                            this.client.capital_gains.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.client.capital_gains.splice(index, 1)
            }
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>