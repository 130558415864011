<template>
    <div>
        <b-row>
            <b-col sm="4">
                <b-form-group label="Client" label-for="value">
                    <v-select
                        v-model="scripture.client_id"
                        :options="clients"
                        label="name"
                        :reduce="(option) => option.id"
                        :disabled="disabled"
                    >
                        <template #no-options="{ }">
                            No s'han pogut carregar els clients
                        </template>
                        <template slot="option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                    </v-select>
                    <small v-if="validation_scripture.client_id" class="text-danger">
                        {{ validation_scripture.client_id }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Categoria" label-for="value">
                    <v-select
                        v-model="scripture.category"
                        :options="scripturesCategories"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_scripture.category" class="text-danger">
                        {{ validation_scripture.category }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Tipus" label-for="value">
                    <v-select
                        v-model="scripture.type"
                        :options="scripturesTypes"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_scripture.type" class="text-danger">
                        {{ validation_scripture.type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Es necessitarà factura" label-for="value">
                    <v-select
                        v-model="scripture.invoice"
                        :options="boolean"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_scripture.invoice" class="text-danger">
                        {{ validation_scripture.invoice }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <b-form-group label="Observacions internes" label-for="observations">
                    <b-form-textarea v-model="scripture.internal_observations" placeholder="Observacions internes"
                                     :class="validation_scripture.internal_observations ? 'is-invalid': ''"/>
                    <small v-if="validation_scripture.internal_observations" class="text-danger">
                        {{ validation_scripture.internal_observations }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Notari" label-for="document">
                    <b-form-input v-model="scripture.notary" placeholder="Notari"/>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group label="Minuta notari" label-for="minuta-notari">
                    <b-form-file
                        v-model="scripture.notary_bill_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="scripture.notary_bill_document" class="mr-2 ml-0">
                <a :href="baseUrl+scripture.notary_bill_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="scripture.notary_bill_document || scripture.notary_bill_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="scripture.notary_bill_document_file = scripture.notary_bill_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Data signatura escriptura" label-for="value">
                    <flat-pickr
                        v-model="scripture.signature_date"
                        class="form-control"
                        :class="validation_scripture.signature_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data signatura escriptura"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_scripture.signature_date" class="text-danger">
                        {{ validation_scripture.signature_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group label="Escriptura" label-for="scripture">
                    <b-form-file
                        v-model="scripture.scripture_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="scripture.scripture_document" class="mr-2 ml-0">
                <a :href="baseUrl+scripture.scripture_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="scripture.scripture_document || scripture.scripture_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="scripture.scripture_document_file = scripture.scripture_document = null"
                />
            </b-col>
            <b-col sm="4">
                <b-form-group label="Protocol" label-for="protocol">
                    <b-form-input v-model="scripture.protocol" placeholder="Protocol"
                                  :class="validation_scripture.protocol ? 'is-invalid': ''"/>
                    <small v-if="validation_scripture.protocol" class="text-danger">
                        {{ validation_scripture.protocol }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data devengo" label-for="value">
                    <flat-pickr
                        v-model="scripture.accrual_date"
                        class="form-control"
                        :class="validation_scripture.accrual_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data devengo"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_scripture.accrual_date" class="text-danger">
                        {{ validation_scripture.accrual_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data limit" label-for="value">
                    <flat-pickr
                        v-model="scripture.limit_date"
                        class="form-control"
                        :class="validation_scripture.limit_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data limit"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_scripture.limit_date" class="text-danger">
                        {{ validation_scripture.limit_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data prescripció" label-for="prescripció">
                    <flat-pickr
                        v-model="scripture.prescription_date"
                        class="form-control"
                        :class="validation_scripture.prescription_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data prescripció"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_scripture.prescription_date" class="text-danger">
                        {{ validation_scripture.prescription_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Estat" label-for="value">
                    <select v-model="scripture.status" class="form-group custom-select"
                            :class="'background-' + scripture.status">-
                        <option v-for="status in scripturesStatus"
                                :key="status.value"
                                :selected="status.value === scripture.status"
                                :value="status.value"> {{ status.name }}
                        </option>
                    </select>
                    <small v-if="validation_scripture.status" class="text-danger">
                        {{ validation_scripture.status }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <hr>
        <capital-gain-form/>
        <div>
            <b-card title="Liquidació" border-variant="primary" class="text-left mt-2">
                <b-row>
                    <b-col sm="4">
                        <b-form-group label="Document" label-for="document">
                            <b-form-file
                                v-model="scripture.liquidation_document_file"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="scripture.liquidation_document" class="mr-2 ml-0">
                        <a :href="baseUrl+scripture.liquidation_document" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 mt-md-2"
                                size="25"
                            />
                        </a>
                        <feather-icon v-if="scripture.liquidation_document || scripture.liquidation_document_file"
                                      icon="TrashIcon"
                                      class="cursor-pointer ml-1 mt-md-2"
                                      size="25"
                                      @click="scripture.liquidation_document_file = scripture.liquidation_document = null "
                        />
                    </b-col>
                </b-row>
                <model600-form/>
                <model210-form/>
                <open-model-form/>
            </b-card>

            <b-card title="Registres" border-variant="primary" class="text-left mt-2">
                <b-col lg="12" md="12" sm="12">
                    <b-row v-for="(property_record, index) in scripture.properties"
                           :key="property_record.id">
                        <b-col sm="3">
                            <b-form-group label="Descripció" label-for="document">
                                <b-form-input v-model="property_record.description" placeholder="Descripció"/>
                            </b-form-group>
                        </b-col>
                        <b-col sm="2">
                            <b-form-group label="Document" label-for="document">
                                <b-form-file
                                    v-model="property_record.document_file"
                                    placeholder="Seleccionar"
                                    drop-placeholder="Seleccionar"
                                    browse-text="Seleccionar"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="property_record.document" class="mr-2 ml-0">
                            <a :href="baseUrl+property_record.document" target="_blank">
                                <feather-icon
                                    icon="EyeIcon"
                                    class="cursor-pointer mt-0 mt-md-2"
                                    size="25"
                                />
                            </a>
                            <feather-icon v-if="property_record.document || property_record.document_file"
                                          icon="TrashIcon"
                                          class="cursor-pointer ml-1 mt-md-2"
                                          size="25"
                                          @click="property_record.document_file = property_record.document = null "
                            />
                        </b-col>
                        <b-col sm="2">
                            <b-form-group label="Data entrada" label-for="date-liquidation">
                                <flat-pickr
                                    v-model="property_record.date_entry"
                                    class="form-control"
                                    name="date"
                                    placeholder="Data entrada"
                                    :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="2">
                            <b-form-group label="Data sortida" label-for="date-liquidation">
                                <flat-pickr
                                    v-model="property_record.date_exit"
                                    class="form-control"
                                    name="date"
                                    placeholder="Data sortida"
                                    :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="1">
                            <b-button
                                variant="outline-danger"
                                class="mt-0 mt-md-2"
                                @click="removePropertyItem(index, property_record.id)"
                            >
                                <feather-icon
                                    icon="TrashIcon"
                                    class="mr-25"
                                />
                            </b-button>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group label="Observacions" label-for="date-liquidation">
                                <b-form-textarea v-model="property_record.observations" placeholder="Observacions"/>
                            </b-form-group>
                        </b-col>
                        <hr/>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex align-items-center justify-content-start" cols="12" md="12">
                            <b-button @click="addNewPropertyRow()">
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-25"
                                />
                                <span>Afegir registre</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>

        </div>
        <model902-form/>
        <b-row>
            <b-col class="col-sm-3">
                <b-form-group label="Comunicació canvis ajuntament" label-for="value">
                    <v-select
                        v-model="scripture.townhall_notification"
                        :options="boolean"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_scripture.townhall_notification" class="text-danger">
                        {{ validation_scripture.townhall_notification }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col class="col-sm-3">
                <b-form-group label="Provisió de fons" label-for="document">
                    <b-form-input v-model="scripture.provision_funds" placeholder="Provisió de fons"/>
                </b-form-group>
            </b-col>
<!--            <b-col>
                <b-form-group label="" label-for="new_construction">
                    <b-form-checkbox
                        id="new_construction"
                        v-model="scripture.new_construction"
                        name="new_construction"
                        unchecked-value="0"
                        :value="1"
                    >
                        Obra nova
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="" label-for="segregation">
                    <b-form-checkbox
                        id="segregation"
                        v-model="scripture.segregation"
                        name="segregation"
                        unchecked-value="0"
                        :value="1"
                    >
                        Segregació
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="" label-for="division">
                    <b-form-checkbox
                        id="division"
                        v-model="scripture.division"
                        name="division"
                        unchecked-value="0"
                        :value="1"
                    >
                        Divisió
                    </b-form-checkbox>
                </b-form-group>
            </b-col>-->
        </b-row>
        <b-row>
            <b-col class="col-sm-3">
                <b-form-group label="Modificació catastral" label-for="value">
                    <v-select
                        v-model="scripture.modification_register"
                        :options="boolean"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_scripture.modification_register" class="text-danger">
                        {{ validation_scripture.modification_register }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col class="col-sm-3">
                <b-form-group label="Comprovació valors catastrals" label-for="value">
                    <v-select
                        v-model="scripture.comprobation_value_register"
                        :options="boolean"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_scripture.comprobation_value_register" class="text-danger">
                        {{ validation_scripture.comprobation_value_register }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col class="col-sm-5">
                <b-form-group label="Comunidad autonoma on es liquida" label-for="value">
                    <v-select
                        v-model="scripture.autonomous_community"
                        :options="autonomousCommunities"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_scripture.autonomous_community" class="text-danger">
                        {{ validation_scripture.autonomous_community }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Valor de referencia" label-for="document">
                    <money class="form-control" v-model="scripture.reference_value" v-bind="money" :class="validation_scripture.reference_value ? 'is-invalid': ''"/>
                    <small v-if="validation_scripture.reference_value" class="text-danger">
                        {{ validation_scripture.reference_value }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="scripture.reference_value_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="scripture.reference_value_document" class="mr-2 ml-0">
                <a :href="baseUrl+scripture.reference_value_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="scripture.reference_value_document || scripture.reference_value_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="scripture.reference_value_document_file = scripture.reference_value_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Valor catastral" label-for="document">
                    <money class="form-control" v-model="scripture.register_value" v-bind="money" :class="validation_scripture.register_value ? 'is-invalid': ''"/>
                    <small v-if="validation_scripture.register_value" class="text-danger">
                        {{ validation_scripture.register_value }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="scripture.register_value_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="scripture.register_value_document" class="mr-2 ml-0">
                <a :href="baseUrl+scripture.register_value_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="scripture.register_value_document || scripture.register_value_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="scripture.register_value_document_file = scripture.register_value_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Coeficient" label-for="document">
                    <b-form-input v-model="scripture.coefficient_value" placeholder="Coeficient"/>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="scripture.coefficient_value_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="scripture.coefficient_value_document" class="mr-2 ml-0">
                <a :href="baseUrl+scripture.coefficient_value_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="scripture.coefficient_value_document || scripture.coefficient_value_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="scripture.coefficient_value_document_file = scripture.coefficient_value_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Valor minim" label-for="document">
                    <money class="form-control" v-model="scripture.minimum_value" v-bind="money" :class="validation_scripture.minimum_value ? 'is-invalid': ''"/>
                    <small v-if="validation_scripture.minimum_value" class="text-danger">
                        {{ validation_scripture.minimum_value }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Document" label-for="document">
                    <b-form-file
                        v-model="scripture.minimum_value_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="scripture.minimum_value_document" class="mr-2 ml-0">
                <a :href="baseUrl+scripture.minimum_value_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon v-if="scripture.minimum_value_document || scripture.minimum_value_document_file"
                              icon="TrashIcon"
                              class="cursor-pointer ml-1 mt-md-2"
                              size="25"
                              @click="scripture.minimum_value_document_file = scripture.minimum_value_document = null "
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <b-form-group label="Observacions" label-for="observations">
                    <b-form-textarea v-model="scripture.observations" placeholder="Observacions"
                                     :class="validation_scripture.observations ? 'is-invalid': ''"/>
                    <small v-if="validation_scripture.observations" class="text-danger">
                        {{ validation_scripture.observations }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <invoices-form></invoices-form>
    </div>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BRow
} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {autonomousCommunities, scripturesCategories, scripturesStatus, scripturesTypes} from "@/constants/trading"
import {baseUrl} from "@/constants/app"
import model600Form from "@/views/clients/form/components/models/model600Form"
import model210Form from "@/views/clients/form/components/models/model210Form"
import model902Form from "@/views/clients/form/components/models/model902Form"
import invoicesForm from "@/views/trading/scriptures/components/invoicesForm"
import openModelForm from "@/views/clients/form/components/models/modelOpenModelForm"
import capitalGainForm from "@/views/clients/form/components/models/capitalGainForm"
import {boolean} from "@/constants/constants"
import Swal from "sweetalert2"
import helpers from "@/resources/helpers/helpers"


const {mapFields} = createHelpers({
    getterType: 'scriptures/getField',
    mutationType: 'scriptures/updateField',
})

export default {
    name: "FormScripturesView",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BButton,
        BCard,
        BFormCheckbox,

        model600Form,
        model902Form,
        model210Form,
        invoicesForm,
        openModelForm,
        capitalGainForm,
        vSelect,
        flatPickr,
    },
    props: ['disabled'],
    data() {
        return {
            SpanishLocale,
            clients: [],

            scripturesTypes,
            scripturesCategories,
            scripturesStatus,
            autonomousCommunities,

            boolean,
            baseUrl,
            helpers
        }
    },
    computed: {
        ...mapState('scriptures', ['validation_scripture']),
        ...mapFields({
            scripture: 'scripture',
        }),
    },
    watch: {
        'scripture.signature_date': function (changes) {
            if (changes) {
                const partsDate = changes.split('-')
                const date = new Date(partsDate[2], partsDate[1] - 1, partsDate[0])
                date.setMonth(date.getMonth() + 1)
                this.scripture.limit_date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                this.scripture.accrual_date = changes
            }
        },
        'scripture.limit_date': function (changes) {
            if (changes) {
                const partsDate = changes.split('-')
                const date = new Date(partsDate[2], partsDate[1] - 1, partsDate[0])
                date.setFullYear(date.getFullYear() + 4)
                this.scripture.prescription_date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
            }
        },
        'scripture.client_id': function (client) {
            if (client) {
                this.getClientData(client)
            }
        },
    },
    beforeMount() {
        this.$store.commit('scripture/RESET_STATE')
        this.$store.commit('client/RESET_STATE')
    },
    mounted() {
        this.getClients()
    },
    methods: {
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients', {
                id: this.$route.params.id,
                relations: ['responsible'],
            })
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },

        addNewPropertyRow() {
            this.scripture.properties.push({
                description: null,
                document: null,
                document_file: null,
                date_entry: null,
                date_exit: null,
                observations: null,
            })
        },
        removePropertyItem(index, propertyId) {

            if (propertyId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('scriptures/deleteScriptureProperty', propertyId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            this.scripture.properties.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.scripture.properties.splice(index, 1)
            }
        },
        addNewRowModel(modelFlag, flag) {
            modelFlag.push({
                flag: flag,
            })
        },
        removeItemModel(modelFlag, index, modelDocumentId) {
            if (modelDocumentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('scriptures/deleteScriptureModelRegister', modelDocumentId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            modelFlag.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                modelFlag.splice(index, 1)
            }
        },
        getClientData(client_id) {
            this.showLoading = true
            this.$store.dispatch('client/getClient', {
                id: client_id,
                relations: ['clientModels'],
            })
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },

    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

hr {
    display: block;
    width: 100%;
    height: 1px;
}

.background-asked_signed {
    color: #ff07caf7;
    border: 1px solid #ff07caf7;
}

.background-signed {
    color: #ffd507;
    border: 1px solid #ffd507;
}

.background-taxes_liquidation {
    color: #ff9f43;
    border: 1px solid #ff9f43;
}

.background-register {
    color: #c843fff5;
    border: 1px solid #c843fff5;
}

.background-finished {
    color: #28c76f;
    border: 1px solid #28c76f;
}

select option[value="asked_signed"] {
    color: #ff07caf7 !important;
    background: #ff07ca40;
}

select option[value="signed"] {
    color: #ffc107 !important;
    background: #f3ff438f
}

select option[value="taxes_liquidation"] {
    color: #ff9f43 !important;
    background: #ff9f434d
}

select option[value="register"] {
    color: #c843fff5 !important;
    background: #D143FF4D
}

select option[value="finished"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}
</style>