<template>

    <b-card title="Model 902" border-variant="primary" class="text-left mt-2">
        <b-col lg="12" md="12" sm="12">
            <b-row v-for="(model_902, index) in client.models_records_902"
                   :key="model_902.id">
                <b-col md="5">
                    <b-form-group label="Document" label-for="document">
                        <b-form-file
                            v-model="model_902.document_file"
                            placeholder="Tria un document"
                            drop-placeholder="Tria un document"
                            browse-text="Seleccionar"
                        />
                    </b-form-group>
                </b-col>
                <div v-if="model_902.document">
                    <a :href="baseUrl+model_902.document" target="_blank">
                        <feather-icon
                            icon="EyeIcon"
                            class="cursor-pointer mt-0 mt-md-2"
                            size="25"
                        />
                    </a>

                </div>
                <b-col lg="3" md="12" sm="12">
                    <b-form-group label="Data" label-for="date">
                        <flat-pickr
                            v-model="model_902.date_updated"
                            class="form-control"
                            placeholder="Data"
                            :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                        />
                    </b-form-group>
                </b-col>
                <feather-icon
                    icon="TrashIcon"
                    class="cursor-pointer mt-md-2"
                    size="25"
                    @click="removeItemModel(index, model_902.id)"
                />
            </b-row>
            <b-row>
                <b-col class="d-flex align-items-center justify-content-start" cols="12" md="12">
                    <b-button @click="addNewRowModel('mod_902')">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                        />
                        <span>Afegir Model 902</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-card>
</template>

<script>
import {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BFormRadio,
    BFormFile,
    BFormTextarea,
    BButton,
    BCard,
    BFormCheckbox
} from 'bootstrap-vue'
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {baseUrl} from "@/constants/app"
import helpers from "@/resources/helpers/helpers"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "Model902",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormTextarea,
        BButton,
        BCard,
        BFormCheckbox,

        vSelect,
        flatPickr,
    },
    data() {
        return {
            CatalanLocale,
            clients: [],
            baseUrl,
            helpers,
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client: 'client',
        }),
    },
    methods: {
        addNewRowModel(flag) {
            this.client.models_records_902.push({
                flag: flag,
            })
        },
        removeItemModel(index, modelDocumentId) {
            if (modelDocumentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('client/deleteClientModel', modelDocumentId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Registre eliminat correctament', 'CheckIcon', 'success')
                            this.client.models_records_902.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar registre', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.client.models_records_902.splice(index, 1)
            }
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>